import Dexie, { Table } from 'dexie'

export default interface RecentParty {
  id: number
  name: string
  uuid: string
  timestamp: string
}

export class RecentParties extends Dexie {
  parties!: Table<RecentParty>

  constructor() {
    super('recent-parties')
    this.version(1).stores({
      parties: '++id,name,uuid,timestamp',
    })
  }

  async getPartyByUuid(uuid: string): Promise<RecentParty | undefined> {
    return this.parties.get({ uuid })
  }

  async updateLastVisited(party: RecentParty): Promise<boolean> {
    const changed = await this.parties.update(party, {
      timestamp: new Date().toISOString(),
    })

    return !!changed
  }

  /**
   * Removes the party from the local db, and returns the amount of parties still in the db
   *
   * @param party The party to remove from the local db
   */
  async removeParty(party: RecentParty): Promise<number> {
    await this.parties.delete(party.id)

    return this.parties.count()
  }

  async addParty(name: string, uuid: string) {
    await this.parties.add({
      name,
      uuid,
      timestamp: new Date().toISOString(),
    } as unknown as RecentParty)
  }

  async updatePartyName(party: RecentParty, name: string) {
    await this.parties.update(party, { name })
  }
}
